import shapeIcon from "../images/icon/mint_live_icon.svg";

const data = [{
    icon: shapeIcon,
    text: "Predict",
  },
  {
    icon: shapeIcon,
    text: "Win",
  },
  {
    icon: shapeIcon,
    text: "Predict",
  },
  {
    icon: shapeIcon,
    text: "Win",
  },
  {
    icon: shapeIcon,
    text: "Predict",
  },
  {
    icon: shapeIcon,
    text: "Win",
  },
  {
    icon: shapeIcon,
    text: "Predict",
  },
  {
    icon: shapeIcon,
    text: "Win",
  },
  {
    icon: shapeIcon,
    text: "Predict",
  },
  {
    icon: shapeIcon,
    text: "Win",
  },
  {
    icon: shapeIcon,
    text: "Predict",
  },
  {
    icon: shapeIcon,
    text: "Win",
  },
];

export default data;