import axios from "axios"; 

const http = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const setAuthToken = (token) => {
  if (token) {
    http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete http.defaults.headers.common["Authorization"];
  }
};

export default http;