import FooterBottom from "./footerBottom/FooterBottom";
import imageFeels from "../../../../assets/images/icon/feelsgood.png";
import btnArrow from "../../../../assets/images/blog/arrow-icon.png";
import Button from "../../../../common/button";

import fb from "../../../../assets/images/icon/fb.png";
import ig from "../../../../assets/images/icon/ig.png";
import twitter from "../../../../assets/images/icon/twitter.png";
import discord from "../../../../assets/images/icon/discord.png";

import FooterStyleWrapper from "./Footer.style";
const Footer = () => {
  return (
    <FooterStyleWrapper>
      <div className="top-footer-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="footer_image">
                <a href="# ">
                  <img src={imageFeels} alt="bithu nft footer logo" />
                </a>
              </div>
            </div>
            <div className="col-md-8 col-lg-8">
              <div className="footer-menu footer_newsletter">
                <h3 className="menu-title text-uppercase">
                  dont miss out on updates!
                </h3>
                <p>
                  Get access to our exclusive content by
                  <br /> registering yourself!
                </p>
                <Button variant="primary" className="button-rbke my-3">
                  register now
                </Button>
                <p>
                  Follow us on our socials for instant
                  <br /> notifications and twitter exclusive content!
                </p>
                <div className="d-flex gap-5 mt-3">
                  <img src={fb} alt="facebook" className="social-icon grab" />
                  <img src={ig} alt="ig" className="social-icon" />
                  <img src={twitter} alt="twitter" className="social-icon" />
                  <img src={discord} alt="discord" className="social-icon" />
                </div>
                {/* <div className="form-box">
                  <input
                    type="text"
                    name="text"
                    placeholder="e.g., email@example.com"
                    required
                  />
                  <button className="btn-yes">YES</button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* footer bottom  */}
      {/* <FooterBottom /> */}
    </FooterStyleWrapper>
  );
};

export default Footer;
