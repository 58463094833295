import { useModal } from "../../../../utils/ModalContext";
import Counter from "../../../../common/counter";
import Button from "../../../../common/button";
import BannerV1Wrapper from "./Banner.style";

import characterThumb from "../../../../assets/images/nft/Character1.png";
import mintLiveDownArrow from "../../../../assets/images/nft/mint_live_down_arrow.svg";
import mintLiveText from "../../../../assets/images/nft/mint_live_text.png";
import homeImageBG from "../../../../assets/images/nft/home_img_bg.png";
import rbkeBanner from "../../../../assets/images/RBKE-Banner.svg";
import logoRekt from "../../../../assets/images/logo.svg";
import { FaTelegram } from "react-icons/fa";

const Banner = () => {
  const { mintModalHandle, connectWalletModalHanlde, account } = useModal();

  return (
    <BannerV1Wrapper id="home">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="bithu_v1_baner_center">
              <div className="img_rektbookie">
                <img src={rbkeBanner} alt="rektbookie" />
              </div>

              <div className="mx-auto p-rbke text-center">
                <p>
                  RektBookie is a revolutionary <strong>AI analysis platform</strong> that uses
                  the power of artificial intelligence to analyze the results of
                  your favorite Football games!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BannerV1Wrapper>
  );
};

export default Banner;
