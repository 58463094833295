import { useModal } from "../../../../utils/ModalContext";
import { useEffect, useState } from "react";
import { FaFutbol, FaWallet, FaRegUserCircle } from "react-icons/fa";
import { MdNotes } from "react-icons/md";
import Button from "../../../../common/button";
import NavWrapper from "./Header.style";
import MobileMenu from "../mobileMenu/MobileMenu";
import logo from "../../../../assets/images/rbke-logo.svg";
import { isMetaMaskInstalled } from "../../../../config";
import Dropdown from "react-bootstrap/Dropdown";

const Header = () => {
  const {
    walletModalHandle,
    walletLoginHandle,
    metamaskModalHandle,
    account,
    displayName,
    email,
    emailLoggedIn,
    isWalletAlreadyConnected,
    disconnectWalletFromApp,
  } = useModal();
  const [isMobileMenu, setMobileMenu] = useState(false);
  const handleMobileMenu = () => {
    setMobileMenu(!isMobileMenu);
  };

  const substr = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) : str;
  };

  const handleWalletConnect = async () => {
    if (!isMetaMaskInstalled()) {
      metamaskModalHandle();
    } else {
      walletModalHandle();
    }
  };

  const handleLogin = async () => {
    walletLoginHandle();
  };

  useEffect(() => {
    const header = document.getElementById("navbar");
    const handleScroll = window.addEventListener("scroll", () => {
      if (window.pageYOffset > 50) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    });

    return () => {
      window.removeEventListener("sticky", handleScroll);
    };
  }, []);

  useEffect(() => {
    isWalletAlreadyConnected();
  }, [isWalletAlreadyConnected]);

  return (
    <NavWrapper className="bithu_header" id="navbar">
      <div className="container-xl p-0">
        {/* Main Menu Start */}
        <div className="bithu_menu_sect">
          <div className="bithu_menu_left_sect">
            <div className="logo">
              <a href="/">
                <img src={logo} alt="bithu nft logo" />
              </a>
            </div>
          </div>
          <div className="bithu_menu_right_sect bithu_v1_menu_right_sect">
            <div className="bithu_menu_list">
              <ul>
                <li>
                  <a href="/about">about</a>
                </li>
                <li>
                  <a href="#">our deck</a>
                </li>
                <li className="submenu">
                  <a href="# ">our social</a>
                  <div className="sub_menu_sect">
                    <ul className="sub_menu_list">
                      <li>
                        <a href="/">twitter</a>
                      </li>
                      <li>
                        <a href="/home-two">instagram</a>
                      </li>
                      <li>
                        <a href="/home-three">facebook</a>
                      </li>
                      <li>
                        <a href="/home-four">discord</a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>

            <div className="bithu_menu_btns">
              <button className="menu_btn" onClick={() => handleMobileMenu()}>
                <MdNotes />
              </button>
              {/* <Button sm variant="outline" className="login_btn klavika">
                <FaFutbol />
                Our Social
              </Button> */}
              {emailLoggedIn ? (
                <Dropdown>
                  <Dropdown.Toggle
                    variant="white"
                    id="dropdown-basic"
                    className="connect_btn klavika text-lowercase"
                  >
                    {/* {email} */}
                    welcome, <span className="win">{displayName}</span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    align={{ lg: "end" }}
                    menuVariant={"primary"}
                    className="rbke-dropdown"
                  >
                    <Dropdown.Item
                      href="#"
                      onClick={() => disconnectWalletFromApp()}
                    >
                      Disconnect
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <Button
                  sm
                  variant="hovered"
                  className="login_btn text-lowercase"
                  onClick={() => handleLogin()}
                >
                  {/* <FaRegUserCircle /> */}
                  login
                </Button>
              )}
            </div>
          </div>
        </div>
        {/* <!-- Main Menu END --> */}
        {isMobileMenu && <MobileMenu mobileMenuhandle={handleMobileMenu} />}
      </div>
    </NavWrapper>
  );
};

export default Header;
