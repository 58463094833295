import Button from "../../../common/button";
import phoneIcon from "../../../assets/images/icon/call-outgoing.svg";
import msgIcon from "../../../assets/images/icon/sms-notification.svg";
import RegisterStyleWrapper from "./Register.style";

const Register = () => {
  return (
    <RegisterStyleWrapper>
      <div className="container">
        <div className="section_tag_line text-center mt-5">
          <h2 className="bebaskai color-primary">create your account</h2>
        </div>
        <div className="row">
          <div className="col-md-6 mx-auto">
            <div className="left_content">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="form_box">
                  <label htmlFor="name">username *</label>
                  <input
                    type="text"
                    placeholder="e.g.  Djordi Werkudara"
                    name="name"
                  />
                </div>

                <div className="form_box">
                  <label htmlFor="mail">Email Addres *</label>
                  <input
                    type="mail"
                    placeholder="e.g.  example@gmail.com"
                    name="mail"
                  />
                </div>

                <div className="form_box">
                  <label htmlFor="phnNo">Password</label>
                  <input
                    type="password"
                    placeholder="Enter your password"
                    name="phnNo"
                  />
                </div>

                <div className="form_box">
                  <label htmlFor="phnNo">Confirm Password</label>
                  <input
                    type="password"
                    placeholder="Confirm your password"
                    name="phnNo"
                  />
                </div>

                <Button lg variant="mint">
                  Submit Now
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </RegisterStyleWrapper>
  );
};

export default Register;
