import { useState } from "react";
import { useModal } from "../../../../utils/ModalContext";
import {
  FaDiscord,
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaWallet,
} from "react-icons/fa";
import { BsXLg } from "react-icons/bs";
import Button from "../../../../common/button";
import logo from "../../../../assets/images/rbke-logo.svg";
// import openseaIcon from "../../../../assets/images/icon/opensea.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { isMetaMaskInstalled } from "../../../../config";
import MobileMenuStyleWrapper from "./MobileMenu.style";

const MobileMenu = ({ mobileMenuhandle }) => {
  const {
    walletLoginHandle,
    walletModalHandle,
    metamaskModalHandle,
    emailLoggedIn,
    displayName,
    account,
    disconnectWalletFromApp,
  } = useModal();
  const [isSubmenu, setSubmenu] = useState(false);

  const handleSubmenu = () => {
    setSubmenu(!isSubmenu);
  };
  const handleLogin = async () => {
    walletLoginHandle();
  };

  const substr = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) : str;
  };

  const handleWalletConnect = async () => {
    if (!isMetaMaskInstalled()) {
      metamaskModalHandle();
    } else {
      walletModalHandle();
    }
  };
  return (
    <MobileMenuStyleWrapper className="bithu_mobile_menu">
      <div className="bithu_mobile_menu_content">
        <div className="mobile_menu_logo">
          <img className="bithu_logo" src={logo} alt="bithu logo" />
          <button
            className="mobile_menu_close_btn"
            onClick={() => mobileMenuhandle()}
          >
            <BsXLg />
          </button>
        </div>
        <div className="bithu_mobile_menu_list">
          <ul>
            <li className="mobile_menu_hide">
              <a href="/about">About Us</a>
            </li>
            <li className="mobile_menu_hide">
              <a href="#home">Our Deck</a>
            </li>
            {/* <li className="mobile_menu_hide">
              <a href="#about">Our Socials</a>
            </li> */}
            {/* <li className="mobile_menu_hide">
              <a href="#roadmap">Prediction</a>
            </li>
            <li className="mobile_menu_hide">
              <a href="#team">Deck</a>
            </li>
            <li className="mobile_menu_hide">
              <a href="#faq">Plans & Pricing</a>
            </li> */}
            {/* <li className="submenu mobile_submenu" onClick={handleSubmenu}>
              <a href="# ">Pages +</a>
              <ul
                className={`sub_menu_list mobile_sub_menu_list ${
                  isSubmenu === true && "submenu_hovered"
                }`}
              >
                <li className="mobile_menu_hide">
                  <a href="/">Home One</a>
                </li>
                <li className="mobile_menu_hide">
                  <a href="/home-two">Home Two</a>
                </li>
                <li className="mobile_menu_hide">
                  <a href="/home-three">Home Three</a>
                </li>
                <li className="mobile_menu_hide">
                  <a href="/blogs">Latest Blog</a>
                </li>
                <li className="mobile_menu_hide">
                  <a href="/post">Blog Details</a>
                </li>
              </ul>
            </li> */}
          </ul>
        </div>
        <div className="mobile_menu_social_links">
          {/* <a href="# ">
            <img src={openseaIcon} alt="bithu social icon" />
          </a> */}
          <a href="# ">
            <FaTwitter />
          </a>
          <a href="# ">
            <FaInstagram />
          </a>
          <a href="# ">
            <FaFacebook />
          </a>
          <a href="# ">
            <FaDiscord />
          </a>
        </div>
        {emailLoggedIn ? (
          <Dropdown>
            <Dropdown.Toggle
              variant="white"
              id="dropdown-basic"
              className="connect_btn klavika text-lowercase"
            >
              {/* {email} */}
              welcome, <span className="win">{displayName}</span>
            </Dropdown.Toggle>

            <Dropdown.Menu
              align={{ md: "end", sm: "end", lg: "end" }}
              menuVariant={"primary"}
              className="rbke-dropdown"
            >
              <Dropdown.Item href="#" onClick={() => disconnectWalletFromApp()}>
                Disconnect
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <Button
            sm
            variant="hovered"
            className="login_btn"
            onClick={() => handleLogin()}
          >
            {/* <FaRegUserCircle /> */}
            Login
          </Button>
        )}
      </div>
    </MobileMenuStyleWrapper>
  );
};

export default MobileMenu;
