import { Button } from "react-bootstrap";
import MatchCardStyleWrapper from "./MatchCard.style";
import { FaAngleRight } from "react-icons/fa";

const MatchCard = ({
  id,
  team2,
  team1,
  match,
  team1_image,
  team2_image,
  home,
  draw,
  away,
  bet,
}) => {
  return (
    <MatchCardStyleWrapper>
      <div className="bithu_card_card">
        <Button className="view_details_btn" href={"/future-predictions/" + id}>
          View Details <FaAngleRight />
        </Button>
        <div className="card_date">{match}</div>
        <div className="versus">
          <div className="teams">
            <img src={team1_image} alt="bithu nft card avatar" />
          </div>
          <span>VS</span>
          <div className="teams">
            <img src={team2_image} alt="bithu nft card avatar" />
          </div>
        </div>
        <div className="tag_versus">
          <h3>{team1}</h3>
          <h3>{team2}</h3>
        </div>
        <div className="stats">
          <div className="row">
            <div className="col-sm-3 col-md-4 col-4">HOME</div>
            <div className="col-sm-3 col-md-4 col-4">DRAW</div>
            <div className="col-sm-3 col-md-4 col-4">AWAY</div>
          </div>
          <div className="row">
            <div className="col-sm-3 col-md-4 col-4">{home.percent}%</div>
            <div className="col-sm-3 col-md-4 col-4">{draw.percent}%</div>
            <div className="col-sm-3 col-md-4 col-4">{away.percent}%</div>
          </div>
          <div className="row">
            <div className="col-sm-3 col-md-4 col-4">{home.value}</div>
            <div className="col-sm-3 col-md-4 col-4">{draw.value}</div>
            <div className="col-sm-3 col-md-4 col-4">{away.value}</div>
          </div>
        </div>
        <div className="bet">
          <span>Our bet:</span>
          <div className="bet_item">{bet}</div>
        </div>
      </div>
    </MatchCardStyleWrapper>
  );
};

export default MatchCard;
