import { useState, useEffect } from "react";
import { ModalContext } from "./ModalContext";
import {
  connectWallet,
  connectWalletLocaly,
  isWalletConnected,
  disconnectWallet,
} from "../config";

import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

import {
  auth
} from "../utils/firebase";
import axios from 'axios';

// const api = axios.create({
//   baseURL: 'http://localhost:80',
//   withCredentials: true,  // This is required for cookies to be set and sent cross-origin
// });

const ContextProvider = ({ children }) => {
  const [visibility, setVisibility] = useState(false);
  const [walletModalvisibility, setModalvisibility] = useState(false);
  const [loginModalvisibility, setLoginModalvisibility] = useState(false);
  const [shareModalVisibility, setShareModalvisibility] = useState(false);
  const [metamaskModalVisibility, setMetamaskModalVisibility] = useState(false);
  const [connectWalletModal, setConnectWalletModal] = useState(false);
  const [account, setAccount] = useState("");
  const [email, setEmail] = useState("");
  const [displayName, setDisplayName] = useState("")
  const [emailLoggedIn, setEmailLoggedIn] = useState("")

  const mintModalHandle = () => {
    setVisibility(!visibility);
  };
  const walletModalHandle = () => {
    setModalvisibility(!walletModalvisibility);
  };
  const walletLoginHandle = () => {
    setLoginModalvisibility(!loginModalvisibility)
  };
  const shareModalHandle = (e) => {
    e.preventDefault();
    setShareModalvisibility(!shareModalVisibility);
  };

  const metamaskModalHandle = () => {
    setMetamaskModalVisibility(!metamaskModalVisibility);
  };

  const connectWalletModalHanlde = () => {
    if (!isWalletConnected()) {
      setConnectWalletModal(!connectWalletModal);
    }
  };

  const connectWalletHandle = async () => {
    const accounts = await connectWallet();
    setAccount(accounts[0]);
    if (!isWalletConnected()) {
      connectWalletLocaly();
    }
    setModalvisibility(!walletModalvisibility);
  };

  const verifySession = async () => {
    try {
      const response = await axios.get('https://rektbookie-api.as.r.appspot.com/v1/auth/verify-session', {
        withCredentials: true
      });

      console.log('response from session',response)
      
      if (response.status === 200 ) {
        // If the session is valid and you get other user info in response, you can set them here
        setEmail(response.data.data.email);
        setDisplayName(response.data.data.fullname);
        setEmailLoggedIn(true);
      } else {
        setEmail("");
        setDisplayName("");
        setEmailLoggedIn(false);
      }
    } catch (error) {
      console.error("Error verifying the session:", error);
      setEmail("");
      setDisplayName("");
      setEmailLoggedIn(false);
    }
  };

  const signOut = async () => {
    try {
      const response = await axios.post('https://rektbookie-api.as.r.appspot.com/v1/logout', {
      },{withCredentials:true});

      console.log('response from session',response)
      
      if (response.status === 200 ) {
        // If the session is valid and you get other user info in response, you can set them here
        setEmail("");
        setDisplayName("");
        setEmailLoggedIn(false);
        setAccount("");
        disconnectWallet();
      } 
    } catch (error) {
      console.error("Error doing logout:", error);
    }
  };

  async function signInWithGoogle(idToken, displayName, lang) {
    const response = await axios.post('https://rektbookie-api.as.r.appspot.com/v1/auth/google-sign-in', {
      idToken,
      displayName,
      // ... you can add more properties like latitude and longitude if needed
    }, {
      headers: {
        'Accept-Language': lang || 'en',  // Defaulting to 'en' if no language provided
        // ... any other headers you might need
        
      },
      withCredentials: true
    },);
  
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Google Sign In failed.');
    }
  }

  const loginHandle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;

      const idToken = await user.getIdToken()

      await signInWithGoogle(idToken,user.displayName)

      console.log('user',user.email)
      setEmail(user.email)
      setDisplayName(user.displayName)
      setEmailLoggedIn(true)
      // IdP data available using getAdditionalUserInfo(result)
      // ...
      setLoginModalvisibility(!loginModalvisibility)
    } catch (error) {
      // Handle Errors here.
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    }

  };

  const isWalletAlreadyConnected = async () => {
    if (isWalletConnected()) {
      const accounts = await connectWallet();
      setAccount(accounts[0]);
    }
  };

  const disconnectWalletFromApp = () => {
    try{
      signOut();
      
    }catch(err){

    }
  };

  useEffect(() => {
    verifySession();
  }, []); // The empty dependency array ensures this runs only once when the component mounts.

  return (
    <ModalContext.Provider
      value={{
        visibility,
        mintModalHandle,
        walletModalHandle,
        walletModalvisibility,
        shareModalVisibility,
        shareModalHandle,
        metamaskModalVisibility,
        metamaskModalHandle,
        loginModalvisibility,
        walletLoginHandle,
        loginHandle,
        account,
        email,
        displayName,
        emailLoggedIn,
        connectWalletHandle,
        isWalletAlreadyConnected,
        disconnectWalletFromApp,
        connectWalletModalHanlde,
        connectWalletModal
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default ContextProvider;
