import SectionTitle from "../../../../common/sectionTitle";
import HistoryStyleWrapper from "./History.style";
import ballImg from "../../../../assets/images/icon/ball.png";
import thinkImg from "../../../../assets/images/rektbookie.webp";
import data from "../history/data";
import { FaAngleRight } from "react-icons/fa";
import stampRbke from "../../../../assets/images/stamp-rbke.png";
import exampleTeam from "../../../../assets/images/match/mainz.png";

const History = () => {
  return (
    <HistoryStyleWrapper className="bithu_about_sect" id="about">
      <div className="container">
        <div className="d-flex flex-column align-items-center mb-5">
          <img
            src={stampRbke}
            alt="stamp"
            className="rbke-league mb-5"
            lazy="loading"
          />
          <h3 className="subtitle klavika">{"what's this?"}</h3>
          <p className="text-center">
            It’s the <strong>Rektbookie’s stamp of approval!</strong> A stamp
            that we grant to matches with slightly lower confidence level bus
            still consider favorable. A badge that marks “honorable mentions”, a
            hot take of our AI. Well suited for those who are feeling lucky and
            adventurous, or just want to try something new!
          </p>
        </div>

        <h2 className="headline text-uppercase">{"past predictions"}</h2>
        <h3 className="subtitle klavika">{"all record of our past matches"}</h3>
        {/* <div className="bithu_about_content">
          <div className="row flex-row align-items-center">
            <table className="header">
              <thead>
                <tr>
                  <th>
                    <h2>MATCH</h2>
                  </th>
                  <th>
                    <h2>HOME</h2>
                  </th>
                  <th>
                    <h2>AWAY</h2>
                  </th>
                  <th>
                    <h2>HOME WIN %</h2>
                  </th>
                  <th>
                    <h2>DRAW %</h2>
                  </th>
                  <th>
                    <h2>AWAY WIN %</h2>
                  </th>
                  <th>
                    <h2>ODDS</h2>
                  </th>
                  <th colSpan="2">
                    <h2>PAYOUT (USD)</h2>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, i) => (
                  <tr className={item.payout < 0 ? "lose" : "win"} key={i}>
                    <td>{item.match}</td>
                    <td>{item.home}</td>
                    <td>{item.away}</td>
                    <td>{item.home_win}</td>
                    <td>{item.draw}</td>
                    <td>{item.away_win}</td>
                    <td>{item.odds}</td>
                    <td>{item.payout}</td>
                    <td className="details">
                      <button>
                        <FaAngleRight />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div> */}
      </div>

      <div className="container-xl rbke-container mt-5">
        <div className="row mb-3 rbke-header">
          <h3 className="col-3 klavika">match date</h3>
          <h3 className="col-3 text-center klavika">match </h3>
          <h3 className="col-3 text-center klavika">ai pick</h3>
          <h3 className="col-3 text-end klavika">result</h3>
        </div>

        <div className="row">
          {[1, 2, 3, 4, 5].map((match, i) => (
            <div className="card card-rbke mb-3">
              <div className="row align-items-center">
                <div className="col-lg-3 col-md-3 col-sm-12">
                  <div className="row align-items-center rbke-stamp-row">
                    <h4 className="klavika color-primary col-lg-9 col-md-9 col-sm-12">06 NOV 2023</h4>
                    <img
                      src={stampRbke}
                      alt="stamp rbke"
                      className="stamp col-lg-3 col-md-3 col-sm-12"
                      lazy="loading"
                    />
                  </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-12">
                  <div className="row align-items-center justify-content-center text-center">
                    <img
                      src={exampleTeam}
                      alt="stamp rbke"
                      className="stamp col-2"
                      lazy="loading"
                    />
                    <h4 className="klavika col-8">
                      machester city vs bournemouth
                    </h4>
                    <img
                      src={exampleTeam}
                      alt="stamp rbke"
                      className="stamp col-2"
                      lazy="loading"
                    />
                  </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-12">
                  <div className="row align-items-center justify-content-center text-center">
                    <h4 className="klavika ">bournemouth to win</h4>
                  </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-12">
                  <div className="row align-items-center justify-content-center text-end">
                    <h4 className={`klavika rbke-aipick ${i % 2 ? "win" : "lose"}`}>
                      bournemouth
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </HistoryStyleWrapper>
  );
};

export default History;
