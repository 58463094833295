import MatchDetailV1 from "./MatchDetail.style";
import { useEffect, useState } from "react";
import http from "../../../../utils/Http";
import { useParams } from "react-router-dom";

const MatchDetail = () => {
  const { id } = useParams();

  const [matchData, setMatchesData] = useState({
    home: {},
    draw: {},
    away: {},
  });

  const transformEndpointData = (match) => {
    const prediction = match.__predictions__[0];
    const homeTeam = match.__HomeTeam__;
    const awayTeam = match.__AwayTeam__;

    return {
      id: match.MatchID,
      match: match.MatchDate.split("T"),
      team1: homeTeam.TeamName,
      team2: awayTeam.TeamName,
      team1_image: homeTeam.TeamLogoURL || null, // Adjust if the default value is not null
      team2_image: awayTeam.TeamLogoURL || null, // Adjust if the default value is not null
      home: {
        percent: prediction.HomeWinPercentage,
        value: parseFloat(prediction.OddsHomeWin),
      },
      draw: {
        percent: prediction.DrawPercentage,
        value: parseFloat(prediction.OddsDraw),
      },
      away: {
        percent: prediction.AwayWinPercentage,
        value: parseFloat(prediction.OddsAwayWin),
      },
      bet: prediction.PredictedOutcome,
    };
  };

  async function fetchMatches() {
    try {
      const response = await http.get(`match/${id}`, {
        withCredentials: true,
      });
      const transformedData = transformEndpointData(response.data.data);
      setMatchesData(transformedData);
    } catch (error) {
      console.error("Error fetching the match data:", error);
    }
  }

  useEffect(() => {
    // Fetch data from the endpoint when the component mounts
    fetchMatches();
  }, []); // Empty dependency array ensures the effect runs once when the component mounts

  return (
    <MatchDetailV1 id="home">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="bithu_v1_baner_center">
              <div className="row w-100 future_detail">
                <div className="col-sm-4 col-md-4 col-4">
                  <h3>{matchData.team1}</h3>
                </div>
                <div className="col-sm-4 col-md-4 col-4">
                  <h3>VS</h3>
                </div>
                <div className="col-sm-4 col-md-4 col-4">
                  <h3>{matchData.team2}</h3>
                </div>
              </div>

              <div className="row w-100 future_detail mb-5">
                <div className="col-sm-4 col-md-4 col-4">
                  <img src={matchData.team1_image} alt="img" />
                </div>
                <div className="col-sm-4 col-md-4 col-4">
                  <div className="row future_detail">
                    <span>Match Date</span>
                    <strong>{matchData.MatchDate}</strong>
                  </div>
                </div>
                <div className="col-sm-4 col-md-4 col-4">
                  <img src={matchData.team2_image} alt="img" />
                </div>
              </div>
              <div className="row w-100 future_detail">
                <div className="col-sm-4 col-md-4 col-4">
                  <div className="odds">
                    <span>Home Odds</span>
                    <h2>{matchData.home.value}</h2>
                  </div>
                  <div className="percent">
                    <span>Home Win Percentage</span>
                    <div className="value">
                      <h2 className="m-0">{matchData.home.percent}</h2>
                      <span>%</span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-md-4 col-4">
                  <div className="odds">
                    <span>Draw Odds</span>
                    <h2>{matchData.draw.value}</h2>
                  </div>
                  <div className="percent">
                    <span>Draw Percentage</span>
                    <div className="value">
                      <h2 className="m-0">{matchData.draw.percent}</h2>
                      <span>%</span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 col-md-4 col-4">
                  <div className="odds">
                    <span>Away Odds</span>
                    <h2>{matchData.away.value}</h2>
                  </div>
                  <div className="percent">
                    <span>Away Win Percentage</span>
                    <div className="value">
                      <h2 className="m-0">{matchData.away.percent}</h2>
                      <span>%</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row future_detail mt-5">
                <span>Out Bet</span>
                <h2>{matchData.bet}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MatchDetailV1>
  );
};

export default MatchDetail;
