import styled from "styled-components";
import sectionBgImage from "../../../../assets/images/bg/rektbookie_v2.png";
import winImg from "../../../../assets/images/win.png";
import loseImg from "../../../../assets/images/lose.png";

const HistoryStyleWrapper = styled.section`
  padding-bottom: 120px;
  padding-top: 80px;
  background: #0F0C26;
  min-height: 950px;
  width: 100%;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;

  .rbke-container {
    h3 {
      color: #fff;
    }

    .color-primary {
      color: #48E5CA;
    }
  }

  .card-rbke {
    color: #fff;
    background: transparent;
    border: 1px solid #48E5CA;
    padding: 12px 2rem;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
    position: relative;
    top: 0;
    cursor: grab;
    user-select: none;


    h4 {
      text-transform: lowercase;
      margin-bottom: 0;
    }

    .color-primary {
      color: #48E5CA;
    }

    img.stamp {
      height: 50px;
      width: 50px;
      padding: 0;
    }

    .win {
      color: #00FF66;
    }

    .lose {
      color: #FF003D;
    }

    &:hover {
      position: relative;
      background: rgb(255 255 255 / 15%);
      top: -6px;
    }
  }


  .details {
    display: block;

    button {
      background: transparent;
      color: #9d6200;
      border: none;
      font-size: 1.2rem;
      border-radius: 7px;
      padding: 7px;
      transition: all 0.3s ease-in-out;
      display: inline-grid;
      
      &:hover {
        border: 0px solid rgb(255, 252, 204);
        background: rgba(255, 252, 204, 0.22);
      }
    }
  }

  .headline {
    color: #48E5CA;
    font-family: "Bebaskai";
    font-size: 120px;
    line-height: 1em;
    text-align: center;
  }

  .subtitle {
    color: #fff;
    font-size: 2rem;
    text-align: center;
  }

  .banner {
    position: relative;
    margin-bottom: 6rem;
    display: grid;
    align-content: center;
    justify-content: center;
    align-items: center;

    .dialog {
      position: absolute;
      margin-top: 5rem;
      margin-left: 10rem;
      width: 330px;

      p {
        color: #000;
        line-height: 20px;
      }
    }
  }

  .header {
    width: 100%;

    thead {
      background: rgb(54 104 77 / 52%);
      height: 6rem;
      
      h2 {
        color: #fff;
        font-size: 20px;
        margin: 0;
        text-align: center;
      }
    }

    tbody {
      .win {
        background: url(${winImg});
        background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;
      }

      .lose {
        background: url(${loseImg});
        background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;
      }

      tr {
        text-align: center;
        height: 65px;
        color: #fff;
        font-weight: 700;
      }

      tr td:last-child {
        font-size: 36px;
      }
    }


    
  }

  .content {
    margin-top: 2rem;
    
    h3 {
      color:#fff
    }
  }

  .bithu_history_content {
    max-width: 770px;
    width: 100%;
    margin: auto;
    position: relative;
  }

  .bithu_history_questions {
    margin-top: 57px;
    position: relative;

    .history_questions {
      position: relative;
      z-index: 1;

      .accordion__item + .accordion__item {
        margin-top: 10px;
      }
    }
  }
  .accordion__item {
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
  }
  .accordion__header {
    h5 {
      display: flex;
      align-items: center;
      height: 76px;
      margin-bottom: 0px;
      padding: 0 29px;
      color: rgba(255, 255, 255, 0.8);
      font-size: 24px;
      line-height: 34px;
      text-transform: none;
    }
  }

  .accordion__body {
    p {
      padding: 0px 48px 24px 29px;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      color: rgba(255, 255, 255, 0.7);
      margin-bottom: 0px;
      max-width: 100%;
    }
  }

  .history_bg_wattermark {
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
  }

  @media only screen and (max-width: 991px) {
    .accordion__header {
      h5 {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .accordion__header {
      h5 {
        font-size: 20px;
        line-height: 25px;
      }
    }

    .accordion__body {
      p {
        font-size: 14px;
        line-height: 25px;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    .headline {
      font-size: 50px;
    }
    .subtitle {
      font-size: 1rem;
    }
    .rbke-header {
      display: none;
    }
    .rbke-aipick {
      text-align: center;
      margin: 1rem 0;
    }
    .rbke-stamp-row {
      text-align: center;
      justify-content: center;
      margin: 1rem 0;
    }
    .accordion__header {
      h5 {
        font-size: 18px;
      }
    }

    .header thead h2 {
      font-size: 12px;
    }

    .header thead {
      height: 4rem;
    }

    tbody {
      font-size: 14px;
    }
    
    .banner {
      margin-bottom: 2rem;

      .dialog {
        position: absolute;
        margin-top: 2rem;
        margin-left: 3rem;
        width: 140px;

        .headline {
          font-size: 1.5rem;
        }

        p {
          font-size: 10px;
          line-height: normal;
        }
      }
    }
  }
`;

export default HistoryStyleWrapper;