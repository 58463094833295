import styled from "styled-components";
import cardImage from "../../../../assets/images/bg/v1_card.jpeg";

const MatchCardStyleWrapper = styled.article`
  .bithu_card_card {
    background: url(${cardImage});
    backdrop-filter: blur(10px);
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    max-width: 100%;
    width: 277px;
    height: 481px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    border-radius: 2rem;
    padding: 0 1rem;

    .view_details_btn {
      background: transparent;
      color: #fff;
      border: none;
      padding: 8px 2rem;
      transition: all 0.3s ease-in-out;

      svg {
        color: #317f81;
      }

      &:hover {
        background: rgb(255 255 255 / 27%);

        svg {
          color: #fff;
        }
      }
    }

    .card_date {
      font-size: 1rem;
      font-weight: bold;
      color: #fff;
    }
    
    .versus {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      flex-direction: row;

      span {
        font-weight: bold;
        color: #fff;
        font-size: 1.2rem;
        margin: 1rem;
      }

      .teams {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100px;
        
        img {
          height: 100px;
        }
      }
    }

    .tag_versus {
      display: flex;
      align-items: center;
      gap: 3rem;
      justify-content: space-around;
      flex-direction: row;

      h3 {
        width: 100px;
        margin-top: .7rem;
        font-size: 1rem;
        overflow-wrap: anywhere;
        line-height: normal;
      }
    }

    .stats {
      color: #fff;
      text-align: center;
      font-weight: 500;
      width: 100%;
      margin-top: 2rem;
    }

    .bet {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: nowrap;
      flex-direction: row;
      color: #fff;
      gap: 1rem;
      margin: 1rem 0;
      
      .bet_item {
        padding: 0.5rem 1rem;
        border: 3px solid #fff;
        border-radius: 8px;
        font-weight: bold;
        font-style: italic;
        width: 165px;
        text-align: center;
      }
    }

    h3 {
      color: #ffffff;
      font-size: 24px;
      line-height: 34px;
      text-align: center;
      margin-top: 28px;
      margin-bottom: 4px;
    }

    h4 {
      font-family: "Inter";
      font-style: normal;
      text-align: center;
      font-size: 16px;
      line-height: 19px;
      color: rgba(255, 255, 255, 0.8);
      margin: 0;
    }
  }

  .card_social_icon {
    width: 100%;
    position: absolute;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;

    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 197px;
      width: 100%;
      margin: 0;
      padding: 0;

      li {
        height: 25px;
        width: 37px;
        background: #0f181d;
        opacity: 0;
        transition: 0.4s;
        a {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          height: 100%;
          width: 100%;
          color: #ffffff;
        }
      }
    }
  }

  .hover_shape {
    position: absolute;
    top: 10px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s;

    &.hov_shape1 {
      left: 10px;
    }

    &.hov_shape2 {
      right: 10px;
      transform: rotate(90deg);
    }
  }

  &:hover {
    .hover_shape {
      opacity: 1;
      visibility: visible;
    }

    &:hover {
      .card_social_icon {
        ul {
          li {
            opacity: 1;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 480px) {
    .bithu_card_card {
      margin: auto;
    }
  }

  @media only screen and (max-width: 576px) {
    .bithu_card_card {
      margin: auto;
    }
  }
  
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .bithu_card_card {
      .bet_item {
        width: unset;
      }
      .tag_versus {
        gap: 0;
      }
      .versus {
        .teams {
          width: 50px;

          img {
            height: unset;
          }
        }
        span {
          margin: 3px;
          font-size: 1rem;
        }
      }
    }
  }
  
  @media only screen and (max-width: 991px) {
    .bithu_card_card {
      h3 {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }
`;

export default MatchCardStyleWrapper;