import SectionTitle from "../../../../common/sectionTitle";
import Counter from "../../../../common/counter";

import aboutImageLeft from "../../../../assets/images/nft/about_image_left.png";
import aboutImageRight from "../../../../assets/images/nft/about_image_right.png";
import AboutInfoCardList from "../aboutInfoCardList";
import AboutStyleWrapper from "./About.style";

const About = () => {
  return (
    <AboutStyleWrapper className="v2_about_us_section" id="about">
      {/* <div className="v2_about_overlay"></div> */}
      <div className="container">
        <h1 className="headline text-uppercase">{"about"}</h1>
        <div className="v2_about_us_content">
          <div className="v2_about_us_text">
            <p>
              Rekt Bookie is a <strong>revolutionary AI-powered research platform that
              utilizes cutting-edge technology to help you make the most
              informed person about upcoming games.</strong> Our AI-driven algorithms are
              developed with the utmost care and precision, powered by deep
              learning algorithms and advanced analytics.
            </p>
            <p>
              We're a team of enthusiasts who strive to push the boundaries of
              AI and see how far it can take us. We're passionate about helping
              people make informed analyses, and AI is the perfect tool. Join us
              in our mission to receive the latest coverage on the future of
              football.
            </p>

            <h2 className="subtitle klavika">
              how does our ai predict <br />
              the outcome?
            </h2>
            <div className="rbke-content">
              <h3 className="klavika color-primary text-center mt-5">
                advanced historical analysis
              </h3>
              <p>
                <strong>
                  Our AI analyses past matches between teams, including player
                  performances and playing conditions,
                </strong>
                to identify important patterns and strategies revealing true
                performance.ll.
              </p>
            </div>

            <div className="rbke-content">
              <h3 className="klavika color-primary text-center mt-5">
                player growth trajectory <br />
                forecasting
              </h3>
              <p>
                <strong>
                  The AI uses machine learning to analyze player impacts on
                  future games
                </strong>
                , factoring in career progression, improvements, and slumps to
                provide quality research & updates on football games.
              </p>
            </div>

            <div className="rbke-content pb-5">
              <h3 className="klavika color-primary text-center mt-5">
                real-time sentiment and <br />
                trend analysis
              </h3>
              <p>
                <strong>
                  Our AI technology monitors real-time tweets and news to gather
                  valuable information about upcoming matches.
                </strong>
                We determine team morale, fan sentiment, and emerging trends
                through natural language processing. This data, combined with
                historical and player information, allows us to provide accurate
                data for live matches.
              </p>
            </div>

            {/* <div className="counter-wrap">
              <h4>
                To be minted <Counter end="9999" />
              </h4>
              <h4>
                Attributes <Counter end="555" />
              </h4>
            </div> */}
          </div>
          {/* <div className="v2_about_img v2_about_img_left">
            <span>
              <img src={aboutImageLeft} alt="bithu nft about" />
            </span>
          </div>
          <div className="v2_about_img v2_about_img_right">
            <span>
              <img src={aboutImageRight} alt="bithu nft thumb" />
            </span>
          </div> */}
        </div>

        {/* about card */}
        {/* <AboutInfoCardList /> */}
      </div>
    </AboutStyleWrapper>
  );
};

export default About;
