import firebase from 'firebase/compat/app';
import  { getAuth } from'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDKjVN2K0l9NVVFErGjIqMxjEFwxe14TZ0",
    authDomain: "rektbookie.firebaseapp.com",
    projectId: "rektbookie",
    storageBucket: "rektbookie.appspot.com",
    messagingSenderId: "266653855868",
    appId: "1:266653855868:web:1517b074388fa4596799f9",
    measurementId: "G-XNQMPNN6LJ"
  };

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const auth = getAuth(firebaseApp);
export default firebaseApp;