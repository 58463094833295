import { useModal } from "../utils/ModalContext";
import GlobalStyles from "../assets/styles/GlobalStyles";
import Header from "../components/section/header/v1/Header";
import Layout from "../common/layout";
import Banner from "../components/section/@rektbookie/banner";
import Counter from "../components/section/counter/v1";
import CoinInfoSlider from "../components/section/coinInfoSlider";
import About from "../components/section/@rektbookie/about";

import CharacterSlider from "../components/section/characterSlider/v1";
import HowToMint from "../components/section/howToMint/v1";
// import About from "../components/section/about/v1";
import RoadMap from "../components/section/roadMap/v1";
import FAQ from "../components/section/faq/v1";
import Footer from "../components/section/footer/v5";
import MintNowModal from "../common/modal/mintNowModal";
import WalletModal from "../common/modal/walletModal/WalletModal";
import WalletLogin from "../common/modal/walletLogin/WalletLogin";
import MetamaskModal from "../common/modal/metamask/MetamaskModal";
import ConnectWallet from "../common/modal/metamask/ConnectWallet";
import Match from "../components/section/@rektbookie/match";
import Doubt from "../components/section/@rektbookie/doubt";
import History from "../components/section/@rektbookie/history/History";

const HomeV1 = () => {
  const {
    visibility,
    walletModalvisibility,
    metamaskModalVisibility,
    loginModalvisibility,
    connectWalletModal,
  } = useModal();
  return (
    <Layout>
      <GlobalStyles />
      {visibility && <MintNowModal />}
      {walletModalvisibility && <WalletModal />}
      {loginModalvisibility && <WalletLogin />}
      {metamaskModalVisibility && <MetamaskModal />}
      {connectWalletModal && <ConnectWallet />}
      <Header />
      <Banner />
      {/* <CoinInfoSlider /> */}
      <About />
      <Match />
      <History />
      {/* <Doubt /> */}
      <Footer />
    </Layout>
  );
};

export default HomeV1;
