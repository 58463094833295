import styled from "styled-components";

const MatchStyleWrapper = styled.section`
  background: #0F0C26;
  backdrop-filter: blur(5px);
  position: relative;
  z-index: 1;

  .v1_match_overlay {
    background: #0F0C26;
    height: 100%;
    width: 100%;
    padding: 100px 0 100px;
  }

  .rbke-container {
    background: #1B1541BF;
    border: 1px solid #48E5CA;
    padding: 3rem;
    border-radius: 8px;

    h2 {
      color: #fff;
    }

    .color-primary {
      color: #48E5CA;
    }

    img.rbke-league {
      height: 200px;
      object-fit: contain;
    }

  }

  .card-rbke {
    color: #fff;
    max-width: 390px;
    height: 290px;
    background: transparent;
    border: 1px solid #48E5CA;
    padding: 1.5rem;
    transition: all 0.3s ease-in-out;
    position: relative;
    top: 0;
    cursor: grab;
    user-select: none;

    img.rbke-match {
      height: 50px;
    }

    h4 {
      font-family: 'Klavika';
      font-size: 1rem;
    }

    &:hover {
      position: relative;
      background: rgb(255 255 255 / 15%);
      top: -10px;
    }

    .font-italic {
      font-style: italic;
    }

    .color-primary {
      color: #48E5CA;
    }

    .detail {
      p {
        margin-bottom: 8px;
      }

      h4, p {
        text-align: center;
      }
    }
  }

  .hidden {
    background: #040404;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    text-align: center;

    img {
      width: 100px;
    }

    &:hover {
      background: #040404;
    }
  }

  .headline {
    color: #48E5CA;
    font-family: "Bebaskai";
    font-size: 120px;
    line-height: 1em;
    text-align: center;
  }

  .subtitle {
    color: #fff;
    font-size: 2rem;
    text-align: center;
  }

  .bithu_match_content {
    margin-top: 53px;
    position: relative;
    z-index: 1;
    .row {
      row-gap: 30px;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .headline {
      font-size: 50px;
    }
    .subtitle {
      font-size: 1rem;
    }
  }
`;

export default MatchStyleWrapper;