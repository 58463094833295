import { useModal } from "../utils/ModalContext";
import GlobalStyles from "../assets/styles/GlobalStyles";
import Header from "../components/section/header/v1/Header";
import Layout from "../common/layout";
import Footer from "../components/section/footer/v5";
import MintNowModal from "../common/modal/mintNowModal";
import WalletModal from "../common/modal/walletModal/WalletModal";
import WalletLogin from "../common/modal/walletLogin/WalletLogin";
import MetamaskModal from "../common/modal/metamask/MetamaskModal";
import ConnectWallet from "../common/modal/metamask/ConnectWallet";
import MatchProof from "../components/section/@rektbookie/matchProof";

const Proof = () => {
  const {
    visibility,
    walletModalvisibility,
    metamaskModalVisibility,
    loginModalvisibility,
    connectWalletModal,
  } = useModal();
  return (
    <Layout>
      <GlobalStyles />
      {visibility && <MintNowModal />}
      {walletModalvisibility && <WalletModal />}
      {loginModalvisibility && <WalletLogin />}
      {metamaskModalVisibility && <MetamaskModal />}
      {connectWalletModal && <ConnectWallet />}
      <Header />
      <MatchProof />
      <Footer />
    </Layout>
  );
};

export default Proof;
