import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";
import modalBg from "../../../assets/images/bg/modal_overlay.png";

const modalAnimation = keyframes`${fadeIn}`;

const WalletModalStyleWrapper = styled.div`
  &.modal_overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999999;
    display: flex;
    justify-content: center;

    &::before {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: #000;
      content: "";
      opacity: 0.92;
      backdrop-filter: blur(33px);
    }
  }

  .color-primary {
    color: #48E5CA;
  }

  .bg-primary {
    background-color: #48E5CA !important;
  }

  label {
    font-family: "Bakbak One";
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.8);
    line-height: 45px;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 5px;
  }

  input,
  textarea {
    border: 2px solid #48E5CA;
    // background: transparent;
    outline: none;
    padding: 8px 21px;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    width: 100%;
    box-sizing: border-box;
  }

  input {
    height: 60px;
  }

  .mint_modal_box {
    position: relative;
    width: 600px;
    margin: auto;
    animation: 1s ${modalAnimation}; 

    &::before {
      backdrop-filter: blur(5px);
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: #171c21;
      content: "";
    }
  }

  .mint_modal_content {
    height: 100%;
    width: 100%;
    background: #1B1541BF;
    // background: url(${modalBg});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 45px;
    padding-bottom: 50px;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .modal_header {
    h2 {
      // font-family: "Bakbak One";
      font-style: normal;
      font-weight: 400;
      font-size: 3rem;
      line-height: 28px;
      text-align: center;
      text-transform: uppercase;
      // color: #ffffff;
      max-width: 280px;
      margin: auto;
      margin-bottom: 26px;
    }

    button {
      background: transparent;
      border: none;
      outline: none;
      height: 45px;
      width: 45px;
      position: absolute;
      right: 0px;
      top: 0px;
      overflow: hidden;
      display: flex;
      justify-content: end;
      align-items: baseline;

      svg {
        margin-top: 5px;
        color: #ffffff;
      }

      &:before {
        content: "";
        background: rgba(255, 255, 255, 0.1);
        height: 150%;
        width: 150%;
        position: absolute;
        right: -35px;
        top: -35px;
        transform: rotate(45deg);
      }
    }
  }

  .modal_body {
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      color: rgba(255, 255, 255, 0.8);
      padding-bottom: 24px;
    }
  }

  .modal_bottom_text {
    color: rgba(255, 255, 255, 0.8);
    a {
      color: #ffffff;
      margin-left: 7px;
    }
  }

  .modal_bottom_shape {
    position: absolute;
    bottom: 10px;

    &.shape_left {
      left: 10px;
      transform: rotate(-90deg);
    }
    &.shape_right {
      right: 10px;
      transform: rotate(180deg);
    }
  }
`;

export default WalletModalStyleWrapper;
