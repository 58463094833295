import { useModal } from "../../../utils/ModalContext";
import { FiX, FiChevronRight } from "react-icons/fi";
import WalletModalStyleWrapper from "./WalletLogin.style";
import hoverShape from "../../../assets/images/icon/hov_shape_L.svg";
import metamaskIcon from "../../../assets/images/icon/MetaMask.svg";
import formatic from "../../../assets/images/icon/Formatic.svg";
import trustWalletIcon from "../../../assets/images/icon/Trust_Wallet.svg";
import walletConnect from "../../../assets/images/icon/WalletConnect.svg";

import { useState } from "react";
// import Button from "react-bootstrap/Button";
import Button from "../../../common/button";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { Alert } from "react-bootstrap";
const WalletLogin = () => {
  const { walletLoginHandle, loginHandle } = useModal();
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <>
      <WalletModalStyleWrapper className="modal_overlay">
        <div className="mint_modal_box">
          <div className="mint_modal_content">
            <div className="modal_header">
              <h2 className="bebaskai color-primary">LOGIN</h2>
              <button onClick={() => walletLoginHandle()}>
                <FiX />
              </button>
            </div>
            <div data-bs-theme="dark" className="modal_body">
              <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
                className="mb-3"
              >
                <Row className="mb-3">
                  <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Form.Label className="klavika text-white">
                      username
                    </Form.Label>
                    <Form.Control required type="text" placeholder="Username" />
                    <Form.Control.Feedback type="invalid">
                      Please provide a username
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="12" controlId="validationCustom03">
                    <Form.Label className="klavika text-white">
                      password
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide a password
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <div className="d-grid gap-2">
                  <Button lg variant="mint" type="submit" className="w-100">
                    Login
                  </Button>

                  {/* <Button
                    size="md"
                    variant="light"
                    type="submit"
                    className="klavika bg-primary"
                  >
                    login
                  </Button> */}
                </div>
              </Form>

              <div className="wallet_list">
                <p>
                  Does'nt have account?{" "}
                  <strong>
                    {" "}
                    <Alert.Link href="/register" key="light" variant="light">
                      Register here!
                    </Alert.Link>
                  </strong>
                </p>
                <div className="d-grid gap-2">
                  <Button
                    onClick={() => loginHandle()}
                    lg
                    block
                    variant="light"
                    className="klavika w-100"
                  >
                    <img
                      src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA"
                      alt="Google"
                      width={15}
                      height={15}
                    />
                    &nbsp; login with google
                  </Button>
                </div>
              </div>
            </div>

            <div className="modal_bottom_shape_wrap">
              <span className="modal_bottom_shape shape_left">
                <img src={hoverShape} alt="bithu nft hover shape" />
              </span>
              <span className="modal_bottom_shape shape_right">
                <img src={hoverShape} alt="bithu nft hover shape" />
              </span>
            </div>
          </div>
        </div>
      </WalletModalStyleWrapper>
    </>
  );
};

export default WalletLogin;
