import SectionTitle from "../../../../common/sectionTitle";
import ABOUTStyleWrapper from "./About.style";
import ballImg from "../../../../assets/images/icon/ball.png";
import Button from "../../../../common/button";
const ABOUT = () => {
  return (
    <ABOUTStyleWrapper className="bithu_about_sect" id="about">
      <div className="container">
        <div className="bithu_about_content">
          <h2 className="headline bebaskai">what is even this?</h2>
          <div className="row flex-row align-items-center rbke-content">
            <div className="col-sm-12 col-md-5 ball-rbke">
              <img lazy="loading" src={ballImg} alt="ball rektbookie" />
            </div>
            <div className="col-md-7">
              <div className="content">
                <h3 className="text-uppercase rbke-h3">how does it work?</h3>
                <p>
                  RektBookie is able to <strong>analyze millions of data points in
                  real-time</strong> to give you the most accurate and up-to-date
                  analysis. With its advanced features, you can perfectly
                  analyze every football game.
                </p>
                <Button variant="primary" className="button-rbke mb-3">Learn more</Button>
                {/* <Button lg variant="outline">
                  Long Version
                </Button> */}
                <p>
                  Or directly jump in and experience our AI by joining our
                  telegram community.
                </p>
                <Button variant="primary" className="button-rbke mb-3">Join Our Telegram</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ABOUTStyleWrapper>
  );
};

export default ABOUT;
