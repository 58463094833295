import Button from "../../../../common/button";
import iconRekt from "../../../../assets/images/icon.png";
import logoRekt from "../../../../assets/images/logo.svg";
import { FaTelegram } from "react-icons/fa";
import MatchProofV1 from "./MatchProof.style";

const MatchProof = () => {
  return (
    <MatchProofV1 id="home">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="bithu_v1_baner_center">
              <div className="row w-100 future_detail mb-5">
                <h3 className="m-0">WE PUT OUR ACTION </h3>
                <h3 className="m-0">WHERE OUR MOUTH IS</h3>
              </div>

              <div className="row w-100 future_detail mb-5">
                <div className="col-sm-12 col-md-12 col-12">
                  <img
                    src="https://static.wixstatic.com/media/001799_885c5c35b22148acb7fc2e1c35c7d420~mv2.png/v1/fill/w_600,h_1565,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/Group%202.png"
                    alt="img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MatchProofV1>
  );
};

export default MatchProof;
