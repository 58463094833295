import { useEffect, useState } from "react";
import MatchCard from "../matchCard";
import MatchStyleWrapper from "./Match.style";
import { useModal } from "../../../../utils/ModalContext";
import http from "../../../../utils/Http";
import exampleLogo from "../../../../assets/images/match/mainz.png";
import exampleLeague from "../../../../assets/images/match/league.png";
import lock from "../../../../assets/images/icon/lock.png";
const Match = () => {
  const { emailLoggedIn } = useModal();

  const [matchesData, setMatchesData] = useState([]);

  const transformEndpointData = (endpointData) => {
    return endpointData.data.map((match) => {
      const prediction = match.__predictions__[0];
      const homeTeam = match.__HomeTeam__;
      const awayTeam = match.__AwayTeam__;

      return {
        id: match.MatchID,
        match: match.MatchDate.split("T")[0],
        team1: homeTeam.TeamName,
        team2: awayTeam.TeamName,
        team1_image: homeTeam.TeamLogoURL || null, // Adjust if the default value is not null
        team2_image: awayTeam.TeamLogoURL || null, // Adjust if the default value is not null
        home: {
          percent: prediction.HomeWinPercentage,
          value: parseFloat(prediction.OddsHomeWin),
        },
        draw: {
          percent: prediction.DrawPercentage,
          value: parseFloat(prediction.OddsDraw),
        },
        away: {
          percent: prediction.AwayWinPercentage,
          value: parseFloat(prediction.OddsAwayWin),
        },
        bet: prediction.PredictedOutcome,
      };
    });
  };

  async function fetchMatches() {
    try {
      const response = await http.get(
        "https://rektbookie-api.as.r.appspot.com/v1/matches/Premier League",
        {
          withCredentials: true,
        }
      );
      const transformedData = transformEndpointData(response.data);
      setMatchesData(transformedData);
    } catch (error) {
      console.error("Error fetching the match data:", error);
    }
  }

  useEffect(() => {
    // Fetch data from the endpoint when the component mounts
    fetchMatches();
  }, [emailLoggedIn]); // Empty dependency array ensures the effect runs once when the component mounts

  return (
    <MatchStyleWrapper>
      <div className="bithu_match_sect v1_match_sect" id="match">
        <div className="v1_match_overlay">
          <div className="container">
            <h2 className="headline text-uppercase">{"match predictions"}</h2>
            <h3 className="subtitle klavika">
              {"here’s our ai takes on the on going match!"}
            </h3>
            <div className="bithu_match_content">
              <div className="row">
                {matchesData?.map((match, i) => (
                  <div key={i} className="col-lg-4 col-md-4 col-sm-4">
                    <MatchCard {...match} />
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="container-xl rbke-container">
            <div className="d-flex flex-column align-items-center">
              <img
                src={exampleLeague}
                alt="exampleLeague"
                className="rbke-league d-flex"
                lazy="loading"
              />
              <h2 className="klavika text-lowercase text-center mt-2">
                Matches On
              </h2>
              <h2 className="color-primary klavika text-lowercase text-center mb-5">
                04-06 Nov 2023
              </h2>
            </div>
            s
            <div className="row justify-content-center gap-3">
              {[1, 2, 3, 4, 5].map((match, i) => (
                <div className="card card-rbke">
                  <div className="row align-items-center mb-3">
                    <div className="col-4 text-center">
                      <img
                        src={exampleLogo}
                        alt="example"
                        className="rbke-match"
                        lazy="loading"
                      />
                    </div>
                    <div className="col-4 text-center">
                      <h4 className="text-lowercase color-primary">
                        06 Nov 2023
                      </h4>
                    </div>
                    <div className="col-4">
                      <img
                        src={exampleLogo}
                        alt="example"
                        className="rbke-match"
                        lazy="loading"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <h4 className="col-5 text-uppercase text-center">
                      machester city
                    </h4>
                    <h4 className="col-2 text-center">VS</h4>
                    <h4 className="col-5 text-uppercase  text-center">
                      BORNEOmouth
                    </h4>
                  </div>
                  <div className="row detail">
                    <div className="col-4">
                      <h4>HOME</h4>
                      <p>68%</p>
                      <p className="font-italic">1.57%</p>
                    </div>
                    <div className="col-4">
                      <h4>DRAW</h4>
                      <p>68%</p>
                      <p className="font-italic">1.57%</p>
                    </div>
                    <div className="col-4">
                      <h4>AWAY</h4>
                      <p>68%</p>
                      <p className="font-italic">1.57%</p>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <h4 className="text-uppercase">
                      ai picks &nbsp;
                      <span className="color-primary">bournemouth to win</span>
                    </h4>
                  </div>
                </div>
              ))}

              <div className="card card-rbke hidden">
                <div className="row justify-content-center">
                  <img src={lock} alt="lock" />
                  <h4 className="color-primary mt-5">member only</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MatchStyleWrapper>
  );
};

export default Match;
